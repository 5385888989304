function exportPdf(elementId, fName) {
  var element = document.getElementById(elementId);

  var opt = {
    pagebreak: {
      mode: 'avoid-all',
    },
    margin: 0,
    filename: `${fName}.pdf`,
    image: {
      type: 'jpeg',
      quality: 1,
    },
    html2canvas: {
      scale: 0.9,
    },
    jsPDF: {
      unit: 'mm',
      format: 'a5',
      orientation: 'p',
    },
  };

  // New Promise-based usage:
  html2pdf().set(opt).from(element).save();
}

$(document).ready(() => {});

function textOnly(element) {
  $(`#${element}`).on('input', function () {
    this.value = this.value.replace(/[^a-zA-Z\u0621-\u064A ]/g, ''); //<-- replace all other than given set of values
  });
}

function numberOnly(element) {
  $(`#${element}`).on('input', function () {
    this.value = this.value.replace(/[^0-9\u0660-\u0669]/g, ''); //<-- replace all other than given set of values
  });
}

function numberDashOnly(element) {
  $(`#${element}`).on('input', function () {
    this.value = this.value.replace(/[^0-9\u0660-\u0669-]/g, ''); //<-- replace all other than given set of values
  });
}

function pdfA5(eId) {
  var element = document.getElementById(eId);
  var doc = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: 'a5',
  });
  html2canvas(element, {
    backgroundColor: null,
    logging: false,
    onrendered: function (canvas) {
      theCanvas = canvas;
      let img = canvas.toDataURL('image/png');
      doc.addImage(img, 'png', 18, 20);
      doc.output('dataurlnewwindow');
    },
  });
}

// function pdfA4(eId) {
//   var element = document.getElementById(eId);
//   var doc = new jsPDF('p', 'mm', 'letter');
//   var width = doc.internal.pageSize.getWidth();
//   var height = doc.internal.pageSize.getHeight();
//   html2canvas(element, {
//     backgroundColor: null,
//     logging: false,
//     scale: 2,
//     onrendered: function (canvas) {
//       theCanvas = canvas;
//       // document.getElementById('ddd').append(canvas)
//       var width = doc.internal.pageSize.getWidth();
//       var height = doc.internal.pageSize.getHeight() - 20;
//       let img = canvas.toDataURL("image/jpeg");
//       doc.addImage(img, 'jpeg', 0, 10, width, height);
//       doc.output('dataurlnewwindow');
//     }
//   });
// }

function pdfA4() {
  // var element = document.getElementById(eId);
  // // var width = element.offsetWidth;
  // // var height = element.offsetHeight;
  // // var width = 2480;
  // // var height = 3508;
  // var dim = [210, 297];
  // var format = "letter";
  // html2canvas(element, {
  //   backgroundColor: null,
  //   logging: false,
  //   dpi: 300,
  //   scale: 1,
  //   onrendered: function (canvas) {
  //     theCanvas = canvas;
  //     let img = canvas.toDataURL("image/jpeg", 1.0);
  //     var pdf = new jsPDF({
  //       orientation: 'p',
  //       format: 'a4',
  //       unit: 'px'
  //     });
  //     pdf.addImage(img, 'JPEG', 10, 10, canvas.width / 2.4, dim[1]);
  //     pdf.output('dataurlnewwindow');
  //   }
  // });
}
